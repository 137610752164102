// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "@firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCkrX6mfhwqOtRtsyNGntxat4vafV_pGdo",
  authDomain: "growx-699bd.firebaseapp.com",
  databaseURL: "https://growx-699bd-default-rtdb.firebaseio.com",
  projectId: "growx-699bd",
  storageBucket: "growx-699bd.appspot.com",
  messagingSenderId: "256820736924",
  appId: "1:256820736924:web:489364079bebc768c14e59",
  measurementId: "G-YS6SQYVJ4K",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const firestore = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;
