import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Timer from "../../shared/Timer";
import "./countdown.css";
import { Modal } from "react-bootstrap";
import RegisterForm from "../register/RegisterForm";
import FullReleaseContent from "../FullReleaseContent/FullReleaseContent";
import LearnMoreContent from "../LearnMoreContent/LearnMoreContent";
import TermsConditionsContent from "../TermsConditionsContent/TermsConditionsContent";
import HgpTermsConditionsContent from "../HgpTermsConditionsContent/HgpTermsConditionsContent";

function Countdown() {
  const [showRegisterModal, setRegisterModalShow] = useState(false);
  const [showFullReleaseModal, setFullReleaseModalShow] = useState(false);
  const [showLearnMoreModal, setLearnMoreModalShow] = useState(false);
  const [showTermsModal, setTermModalShow] = useState(false);
  const [showHgpTermsModal, setHgpTermModalShow] = useState(false);
  const [goBackRoute, setGoBackRoute] = useState("");

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "/images/hero-01.svg",
    "/images/hero-02.svg",
    "/images/hero-03.svg",
    "/images/hero-04.svg",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      // Update the current image index, wrapping around to the first image if at the end
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [currentImageIndex, images.length]);

  const handleClose = () => setRegisterModalShow(false);
  const handleShow = () => setRegisterModalShow(true);

  const handleFullReleaseClose = () => setFullReleaseModalShow(false);
  const handleFullReleaseShow = () => setFullReleaseModalShow(true);

  const handleLearnMoreClose = () => setLearnMoreModalShow(false);
  const handleLearnMoreShow = () => setLearnMoreModalShow(true);

  const handleTermsClose = () => setTermModalShow(false);
  const handleTermsShow = () => setTermModalShow(true);

  const handleHgpTermsClose = () => setHgpTermModalShow(false);
  const handleHgpTermsShow = () => setHgpTermModalShow(true);

  const openLearnMoreModal = (goBack) => {
    setGoBackRoute(goBack);
    handleFullReleaseClose();
    handleLearnMoreShow();
  };

  const openRegisterModal = (goBack) => {
    setGoBackRoute(goBack);
    handleLearnMoreClose();
    handleHgpTermsClose();
    handleTermsClose();
    handleShow();
  };

  const openTermConditionModal = (goBack) => {
    setGoBackRoute(goBack);
    handleLearnMoreClose();
    handleTermsShow();
  };

  const openHgpTermConditionModal = (goBack) => {
    setGoBackRoute(goBack);
    handleLearnMoreClose();
    handleHgpTermsShow();
  };

  const openModalBack = () => {
    if (goBackRoute === "full-release-modal") {
      handleFullReleaseShow();
    } else if (goBackRoute === "learn-more-modal") {
      handleLearnMoreShow();
    }
  };

  return (
    <>
      <div className="countdown-page">
        <div
          className="hero"
          style={{ backgroundImage: `url("./images/hero-image.jpeg")` }}
        >
          <Container>
            <div className="hero-content pt-md-2 pt-4">
              <div className="welcome-img">
                <img
                  src="/images/welcome-growx.svg"
                  className="img-fluid"
                  alt="GrowX"
                />
              </div>
              <div className="py-md-0 py-5">
                <div className="get-registered d-flex align-items-center pb-md-5 pb-4">
                  <h1>Get registered to receive&nbsp;</h1>
                  <img
                    src={images[currentImageIndex]}
                    alt={`Image ${currentImageIndex + 1}`}
                    className="img-fluid pointer"
                    onClick={() => {
                      setGoBackRoute("");
                      openRegisterModal();
                    }}
                  />
                </div>
              </div>
              <div className="get-registered d-flex flex-column align-items-center pb-md-4 pb-2 pt-4">
                <div
                  className="now-registering"
                  onClick={() => {
                    setGoBackRoute("");
                    openRegisterModal();
                  }}
                >
                  NOW REGISTERING
                </div>
                <h1 className="pt-md-4 pt-3 text-center">
                  AppStore (IOS), GooglePlay (Android), and Full Web Experience
                </h1>
                <h1 className="pt-3 text-center">
                  <b className="theme-color">Live 04.08.2024</b>
                </h1>
              </div>
              <div className="countdown">
                <Timer targetDate={new Date("2024-04-08 00:00:00")} />
              </div>
              <div className="line mb-4"></div>
            </div>
          </Container>
        </div>
        <div
          className="bg-img-section"
          style={{ backgroundImage: `url("./images/vector.png")` }}
        >
          {/* tail blazing section */}
          <div className="tail-blazing-section">
            <Container>
              <div className="row">
                <div className="col-md-5 col-6 m-auto">
                  <div className="powered-by-growx text-center pb-md-4 pb-0">
                    <img
                      src="/images/powered-by-growx.svg"
                      className="img-fluid pointer"
                      alt="GrowX"
                      onClick={() => {
                        setGoBackRoute("");
                        openRegisterModal();
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="section-heading text-center py-lg-0 py-3">
                <span className="theme-color">Trailblazing</span>{" "}
                <span>Features</span>
              </div>
              <div className="features">
                <div className="row">
                  <div className="col-sm-4 mb-md-0 mb-4">
                    <img
                      src="/images/grow-log.svg"
                      className="img-fluid pointer"
                      alt="GrowX"
                      onClick={() => {
                        setGoBackRoute("");
                        openRegisterModal();
                      }}
                    />
                  </div>
                  <div className="col-sm-4 mb-md-0 mb-4">
                    <img
                      src="/images/grow-tracker.svg"
                      className="img-fluid pointer"
                      alt="GrowX"
                      onClick={() => {
                        setGoBackRoute("");
                        openRegisterModal();
                      }}
                    />
                  </div>
                  <div className="col-sm-4 mb-md-0 mb-4">
                    <img
                      src="/images/grow-master.svg"
                      className="img-fluid pointer"
                      alt="GrowX"
                      onClick={() => {
                        setGoBackRoute("");
                        openRegisterModal();
                      }}
                    />
                  </div>
                </div>
              </div>
            </Container>
          </div>

          {/* general public release date */}
          <div className="general-public-release-section">
            <Container>
              <div className="section-text text-center">
                HGXTV Web and Mobile Experience. Live 5-8-24
              </div>
              <div className="countdown">
                <Timer targetDate={new Date("2024-05-08 00:00:00")} />
              </div>
              <div
                className="view-full-release"
                onClick={handleFullReleaseShow}
              >
                View Full Release Schedule
              </div>
            </Container>
          </div>
        </div>
        {/* gray logo */}
        <div className="gray-logo container-fluid">
          <img src="/images/gray-logo.svg" className="img-fluid" alt="GrowX" />
        </div>
      </div>
      <Modal show={showRegisterModal} onHide={handleClose} size="xl" centered>
        <Modal.Body
          className="register-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          {goBackRoute ? (
            <div
              className="go-back"
              onClick={() => {
                handleClose();
                handleLearnMoreShow();
              }}
            >
              <img src="./images/go-back.svg" className="" alt="close" />
            </div>
          ) : (
            ""
          )}
          <div className="close-modal" onClick={handleClose}>
            <img src="./images/close.svg" className="" alt="close" />
          </div>
          <RegisterForm />
        </Modal.Body>
      </Modal>
      <Modal
        show={showFullReleaseModal}
        onHide={handleFullReleaseClose}
        size="xl"
        centered
      >
        <Modal.Body
          className="full-release-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          <div className="close-modal" onClick={handleFullReleaseClose}>
            <img src="./images/close.svg" className="" alt="close" />
          </div>
          <FullReleaseContent
            openLearnMoreModal={() => {
              openLearnMoreModal("full-release-modal");
            }}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showLearnMoreModal}
        onHide={handleLearnMoreClose}
        size="xl"
        centered
      >
        <Modal.Body
          className="learn-more-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          {goBackRoute ? (
            <div
              className="go-back"
              onClick={() => {
                handleLearnMoreClose();
                handleFullReleaseShow();
              }}
            >
              <img src="./images/go-back.svg" className="" alt="close" />
            </div>
          ) : (
            ""
          )}
          <div className="close-modal" onClick={handleLearnMoreClose}>
            <img src="./images/close.svg" className="" alt="close" />
          </div>
          <LearnMoreContent
            openRegisterModal={openRegisterModal}
            openTermConditionModal={openTermConditionModal}
            openHgpTermConditionModal={openHgpTermConditionModal}
          />
        </Modal.Body>
      </Modal>
      <Modal show={showTermsModal} onHide={handleTermsClose} size="xl" centered>
        <Modal.Body
          className="terms-condition-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          {goBackRoute ? (
            <div
              className="go-back"
              onClick={() => {
                handleTermsClose();
                handleLearnMoreShow();
              }}
            >
              <img src="./images/go-back.svg" className="" alt="close" />
            </div>
          ) : (
            ""
          )}
          <div className="close-modal" onClick={handleTermsClose}>
            <img src="./images/close.svg" className="" alt="close" />
          </div>
          <TermsConditionsContent openRegisterModal={openRegisterModal} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showHgpTermsModal}
        onHide={handleHgpTermsClose}
        size="xl"
        centered
      >
        <Modal.Body
          className="terms-condition-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          {goBackRoute ? (
            <div
              className="go-back"
              onClick={() => {
                handleHgpTermsClose();
                handleLearnMoreShow();
              }}
            >
              <img src="./images/go-back.svg" className="" alt="close" />
            </div>
          ) : (
            ""
          )}
          <div className="close-modal" onClick={handleHgpTermsClose}>
            <img src="./images/close.svg" className="" alt="close" />
          </div>
          <HgpTermsConditionsContent openRegisterModal={openRegisterModal} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Countdown;
