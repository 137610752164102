import React, { useState, useEffect } from "react";
import "./Timer.css";

const styles = {
  countdown: {
    alignItems: "center",
    marginBottom: "10px",
    justifyContent: "center",
  },
  countdownSection: {
    display: "flex",
    alignItems: "center",
    marginLeft: "8px",
    marginRight: "8px",
  },
  countdownSectionDigit: {
    borderRadius: "26px",
    padding: "20px 10px",
    backgroundColor: "#323232",
    fontSize: "70px",
    fontWeight: "bold",
    color: "#25ADB1",
    minWidth: "82px",
    height: "106px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "4px",
    marginRight: "4px",
    background:
      "linear-gradient(to bottom, #090909, rgba(65, 65, 65, 0.2), #9B9B9B)",
  },
};

const Timer = (props) => {
  const { targetDate, timerSize } = props;

  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate.getTime() - now.getTime();

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return { days, hours, minutes, seconds };
    } else {
      // The target date has passed
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(timerInterval);
  }, [targetDate]);

  const separateDigits = (number) => {
    const digits = number.toString().split("");
    // If it's a single digit, add a leading zero
    if (digits.length === 1) {
      digits.unshift("0");
    }
    return digits.map((digit, index) => (
      <span
        key={index}
        className="countdown-section-digit"
        style={styles.countdownSectionDigit}
      >
        <div className="countdown-line"></div>
        {digit}
      </span>
    ));
  };

  return (
    <div className={timerSize + " timer d-flex"} style={styles.countdown}>
      <div className="format-wrap">
        <div className="time days" style={styles.countdownSection}>
          {separateDigits(timeLeft.days)}
        </div>
        <div className="countdown-format-text">DAYS</div>
      </div>
      <div className="format-wrap">
        <div className="time hours" style={styles.countdownSection}>
          {separateDigits(timeLeft.hours)}
        </div>
        <div className="countdown-format-text">HOURS</div>
      </div>
      <div className="format-wrap">
        <div className="time minutes" style={styles.countdownSection}>
          {separateDigits(timeLeft.minutes)}
        </div>
        <div className="countdown-format-text">MINUTES</div>
      </div>
      <div className="format-wrap">
        <div className="time seconds" style={styles.countdownSection}>
          {separateDigits(timeLeft.seconds)}
        </div>
        <div className="countdown-format-text">SECONDS</div>
      </div>
    </div>
  );
};

export default Timer;
