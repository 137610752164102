import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./RegisterForm.css";
import axios from "axios";
import { setDoc, doc, collection } from "@firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "@firebase/auth";
import { firestore } from "../../firebase";

function RegisterForm(props) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [defaultPassword, setDefaultPassword] = useState("letsgrow");
  const [error, setError] = useState("");
  const ref = collection(firestore, "Grower");
  const { closeRegisterModal } = props;

  const states = [
    {
      name: "Alabama",
      abbreviation: "AL",
    },
    {
      name: "Alaska",
      abbreviation: "AK",
    },
    {
      name: "American Samoa",
      abbreviation: "AS",
    },
    {
      name: "Arizona",
      abbreviation: "AZ",
    },
    {
      name: "Arkansas",
      abbreviation: "AR",
    },
    {
      name: "California",
      abbreviation: "CA",
    },
    {
      name: "Colorado",
      abbreviation: "CO",
    },
    {
      name: "Connecticut",
      abbreviation: "CT",
    },
    {
      name: "Delaware",
      abbreviation: "DE",
    },
    {
      name: "District Of Columbia",
      abbreviation: "DC",
    },
    {
      name: "Federated States Of Micronesia",
      abbreviation: "FM",
    },
    {
      name: "Florida",
      abbreviation: "FL",
    },
    {
      name: "Georgia",
      abbreviation: "GA",
    },
    {
      name: "Guam",
      abbreviation: "GU",
    },
    {
      name: "Hawaii",
      abbreviation: "HI",
    },
    {
      name: "Idaho",
      abbreviation: "ID",
    },
    {
      name: "Illinois",
      abbreviation: "IL",
    },
    {
      name: "Indiana",
      abbreviation: "IN",
    },
    {
      name: "Iowa",
      abbreviation: "IA",
    },
    {
      name: "Kansas",
      abbreviation: "KS",
    },
    {
      name: "Kentucky",
      abbreviation: "KY",
    },
    {
      name: "Louisiana",
      abbreviation: "LA",
    },
    {
      name: "Maine",
      abbreviation: "ME",
    },
    {
      name: "Marshall Islands",
      abbreviation: "MH",
    },
    {
      name: "Maryland",
      abbreviation: "MD",
    },
    {
      name: "Massachusetts",
      abbreviation: "MA",
    },
    {
      name: "Michigan",
      abbreviation: "MI",
    },
    {
      name: "Minnesota",
      abbreviation: "MN",
    },
    {
      name: "Mississippi",
      abbreviation: "MS",
    },
    {
      name: "Missouri",
      abbreviation: "MO",
    },
    {
      name: "Montana",
      abbreviation: "MT",
    },
    {
      name: "Nebraska",
      abbreviation: "NE",
    },
    {
      name: "Nevada",
      abbreviation: "NV",
    },
    {
      name: "New Hampshire",
      abbreviation: "NH",
    },
    {
      name: "New Jersey",
      abbreviation: "NJ",
    },
    {
      name: "New Mexico",
      abbreviation: "NM",
    },
    {
      name: "New York",
      abbreviation: "NY",
    },
    {
      name: "North Carolina",
      abbreviation: "NC",
    },
    {
      name: "North Dakota",
      abbreviation: "ND",
    },
    {
      name: "Northern Mariana Islands",
      abbreviation: "MP",
    },
    {
      name: "Ohio",
      abbreviation: "OH",
    },
    {
      name: "Oklahoma",
      abbreviation: "OK",
    },
    {
      name: "Oregon",
      abbreviation: "OR",
    },
    {
      name: "Palau",
      abbreviation: "PW",
    },
    {
      name: "Pennsylvania",
      abbreviation: "PA",
    },
    {
      name: "Puerto Rico",
      abbreviation: "PR",
    },
    {
      name: "Rhode Island",
      abbreviation: "RI",
    },
    {
      name: "South Carolina",
      abbreviation: "SC",
    },
    {
      name: "South Dakota",
      abbreviation: "SD",
    },
    {
      name: "Tennessee",
      abbreviation: "TN",
    },
    {
      name: "Texas",
      abbreviation: "TX",
    },
    {
      name: "Utah",
      abbreviation: "UT",
    },
    {
      name: "Vermont",
      abbreviation: "VT",
    },
    {
      name: "Virgin Islands",
      abbreviation: "VI",
    },
    {
      name: "Virginia",
      abbreviation: "VA",
    },
    {
      name: "Washington",
      abbreviation: "WA",
    },
    {
      name: "West Virginia",
      abbreviation: "WV",
    },
    {
      name: "Wisconsin",
      abbreviation: "WI",
    },
    {
      name: "Wyoming",
      abbreviation: "WY",
    },
  ];

  useEffect(() => {}, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (data) => {
    let payload = JSON.stringify({
      sender: {
        name: "GrowX A.i",
        email: "info@thehomegrownpros.com",
      },
      to: [
        {
          name: `${data.firstName} ${data.lastName}`,
          email: `${data.email}`,
        },
      ],
      subject: "Welcome to the Growx A.i Family!",
      htmlContent: `
      <!DOCTYPE html>
        <html lang="en">
          <head> </head>
          <body>
            <p>Dear ${data.firstName} ${data.lastName},</p>
            <p style="margin-bottom:12px">A warm welcome to the Growx A.i family!</p>
            <p style="margin-bottom:12px">
              We are thrilled to inform you that you have successfully subscribed to our exclusive gift offer 1-year free plan, granting you complimentary access to our services for the next twelve months.
            </p>
            <p style="margin-bottom:12px"> 
              Thank you for registering in the beta launch of “The GrowX A.i App”. The most prolific grower support technology in the world. We’re thrilled to have you take part in this monumental moment in our industries history as well as our companies history.To the growers around the world who understand that X is the variable of the seed you wish to nurture, love, and grow.
            </p>
            <p style="margin-bottom:0px">What is your X ?</p>
            <p style="margin-bottom:0px">Exciting news! Growx AI will officially be available on both the Play Store for our android users and the iOS users can find us in your App Store starting February 8, 2024.</p>
            <p style="margin-top:0px">The GrowX A.i App,  will be officially available on both the Play Store and the iOS App Store starting February 8, 2024. Mark your calendar for this date, and get ready to experience the power of GrowX A.i. Your insights and feedback mean the world to us and serve as a valuable asset as we embark on this grow journey together.</p>
            <p style="margin-top:0px">We eagerly anticipate the opportunity to grow with you.</p>
            <p style="margin-top:0px">Happiness and Growth,</p>
            <p style="margin-top:0px">TEAM GROWX A.i 💚</p>
            <p style="margin-top:0px">
              <i>“The most prolific grower support technology in the world, “The GrowX Ai App””</i>
            </p>
            <p>
              As requested, here are your account credentials:
            </p>
            <p>
              Email: ${data.email} <br/>
              Password: ${defaultPassword} 
            </p>
            <p style="margin-top:0px">
              #LoveNeverEnds 💚  #FreeThePlant 🌱  #PlantsOverPills 💊  #Free40K 🔒
            </p>
          </body>
        </html>
      `,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.brevo.com/v3/smtp/email",
      headers: {
        accept: "application/json",
        "api-key":
          "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",
        "content-type": "application/json",
      },
      data: payload,
    };
    createUserAuth(config, data);
  };

  async function sendEmail(config, grower) {
    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  }

  async function saveGrower(config, grower, id) {
    try {
      const myDocumentData = {
        ...grower,
        token: "",
        email: grower.email,
        name: `${grower.firstName} ${grower.lastName}`,
        username: `${extractUsername(grower.email)}`,
        phone: grower.number,
        user_type: "grower",
        created: "",
        password: defaultPassword,
        photo: "",
        request: "",
        subscription: {
          amount: 0,
          type: "Basic",
        },
      };
      const myDocRef = doc(ref, id);
      await setDoc(myDocRef, myDocumentData);
      setSuccess(true);
      setLoading(false);
      // if (closeRegisterModal) {
      //   closeRegisterModal();
      // }
      // sendEmail(config, grower);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  function extractUsername(email) {
    // Split the email address by '@' symbol
    var parts = email.split("@");
    // The username is the first part before the '@' symbol
    var username = parts[0];
    return username;
  }

  async function createUserAuth(config, grower) {
    setLoading(true);
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, grower.email, defaultPassword)
      .then((userCredential) => {
        saveGrower(config, grower, userCredential.user.uid);
        setLoading(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        if (errorCode === "auth/email-already-in-use") {
          setError("Email already registered!");
        }
        setLoading(false);
      });
  }

  if (!success && error) {
    return (
      <div className="register-form">
        <div className="success-message">
          <h4>{error}</h4>
          <br/>
          <button
            className="btn btn-primary btn-lg rounded font-bold mt-2"
            onClick={() => {
              setError("");
              setSuccess(false);
            }}
          >
            GET REGISTERED
          </button>
        </div>
      </div>
    );
  }

  if (success) {
    return (
      <div className="register-form">
        <div className="success-message">
          Thank you for registering, Let’s Grow 🌱
          <br />
          Please check your registration email for further instructions.
        </div>
      </div>
    );
  }

  if (!error && !success) {
    return (
      <div className="register-form">
        <h1>GET REGISTERED</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>First Name:</label>
                <input
                  type="text"
                  {...register("firstName", {
                    required: "First name is required",
                  })}
                  className={
                    errors.firstName
                      ? "form-control invalid-input"
                      : "form-control"
                  }
                  aria-invalid={errors.firstName ? "true" : "false"}
                />
                {errors.firstName && (
                  <div className="invalid-feedback">
                    {errors.firstName.message}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Last Name:</label>
                <input
                  type="text"
                  {...register("lastName", {
                    required: "Last name is required",
                  })}
                  className={
                    errors.lastName
                      ? "form-control invalid-input"
                      : "form-control"
                  }
                  aria-invalid={errors.lastName ? "true" : "false"}
                />
                {errors.lastName && (
                  <div className="invalid-feedback">
                    {errors.lastName.message}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Email:</label>
                <input
                  type="text"
                  {...register("email", {
                    required: "Email Address is required",
                    email: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Please enter a valid email address",
                    },
                  })}
                  className={
                    errors.email ? "form-control invalid-input" : "form-control"
                  }
                  aria-invalid={errors.email ? "true" : "false"}
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email.message}</div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>State:</label>
                <select
                  {...register("state", {
                    required: "Please choose your state",
                  })}
                  className={
                    errors.state ? "form-control invalid-input" : "form-control"
                  }
                  aria-invalid={errors.state ? "true" : "false"}
                >
                  <option value="">Choose an option</option>
                  {states.map((state, index) => (
                    <option key={index} value={state.abbreviation}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {errors.state && (
                  <div className="invalid-feedback">{errors.state.message}</div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Text Ph Number:</label>
                <input
                  type="tel"
                  {...register("number", {
                    required: "Phone number is required",
                    minLength: {
                      value: 10,
                      message: "Phone number must be at least 10 digits long.",
                    },
                    pattern: {
                      value: /^[0-9]*$/,
                      message:
                        "Phone number must contain only numeric characters.",
                    },
                  })}
                  className={
                    errors.number
                      ? "form-control invalid-input"
                      : "form-control"
                  }
                  aria-invalid={errors.number ? "true" : "false"}
                />
                {errors.number && (
                  <div className="invalid-feedback">
                    {errors.number.message}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Are you a grower?</label>
                <select
                  {...register("grower", {
                    required: "Please choose an option",
                  })}
                  className={
                    errors.grower
                      ? "form-control invalid-input"
                      : "form-control"
                  }
                  aria-invalid={errors.grower ? "true" : "false"}
                >
                  <option value="">Choose an option</option>
                  <option value="I am a new Home Grower">
                    I am a new Home Grower
                  </option>
                  <option value="I am a experienced Home Grower,">
                    I am a experienced Home Grower,
                  </option>
                  <option value="I am a Commercial Grower">
                    I am a Commercial Grower
                  </option>
                  <option value="I am Interested in Investing in (GXAi)">
                    I am Interested in Investing in (GXAi)
                  </option>
                  <option value="I am a potential Partner Brand">
                    I am a potential Partner Brand
                  </option>
                  <option value="I am looking for Employment">
                    I am looking for Employment
                  </option>
                </select>
                {errors.grower && (
                  <div className="invalid-feedback">
                    {errors.grower.message}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col text-center">
              <button
                className="btn btn-primary btn-lg rounded font-bold"
                disabled={loading}
              >
                {!loading ? "SUBMIT" : "Submitting..."}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default RegisterForm;
