import { getAuth, onAuthStateChanged, signOut } from "@firebase/auth";
import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import HgpTermsConditionsContent from "../HgpTermsConditionsContent/HgpTermsConditionsContent";
import LearnMoreContent from "../LearnMoreContent/LearnMoreContent";
import TermsConditionsContent from "../TermsConditionsContent/TermsConditionsContent";
import RegisterForm from "../register/RegisterForm";
import "./NewHeader.css";

const styles = {
  button: {
    backgroundColor: "#FFFFFF",
    color: "#25ADB1",
    fontSize: "13px",
    border: "none",
    width: "150px",
    height: "36px",
    borderRadius: "20px",
    fontWeight: "500",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  },
  buttonLearnMore: {
    backgroundColor: "#FFFFFF",
    color: "#25ADB1",
    fontSize: "13px",
    border: "none",
    height: "36px",
    borderRadius: "20px",
    fontWeight: "500",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    width: "130px",
  },
};

function NewHeader() {
  const [showRegisterModal, setRegisterModalShow] = useState(false);
  const [showLearnMoreModal, setLearnMoreModalShow] = useState(false);
  const [showTermsModal, setTermModalShow] = useState(false);
  const [showHgpTermsModal, setHgpTermModalShow] = useState(false);
  const [goBackRoute, setGoBackRoute] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(undefined);
  const history = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, async (userDetail) => {
      if (userDetail) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  }, []);

  const handleClose = () => {
    setGoBackRoute("");
    setRegisterModalShow(false);
  };
  const handleShow = () => setRegisterModalShow(true);

  const handleLearnMoreClose = () => {
    setGoBackRoute("");
    setLearnMoreModalShow(false);
  };
  const handleLearnMoreShow = () => setLearnMoreModalShow(true);

  const handleTermsClose = () => {
    setGoBackRoute("");
    setTermModalShow(false);
  };
  const handleTermsShow = () => setTermModalShow(true);

  const handleHgpTermsClose = () => {
    setGoBackRoute("");
    setHgpTermModalShow(false);
  };
  const handleHgpTermsShow = () => setHgpTermModalShow(true);

  const openRegisterModal = () => {
    handleLearnMoreClose();
    handleHgpTermsClose();
    handleTermsClose();
    handleShow();
  };

  const openTermConditionModal = () => {
    handleLearnMoreClose();
    handleTermsShow();
  };

  const openHgpTermConditionModal = () => {
    handleLearnMoreClose();
    handleHgpTermsShow();
  };

  const signOuNow = async () => {
    if (window.confirm("Are you sure you want to sign out?")) {
      try {
        const auth = getAuth();
        signOut(auth);
        setIsLoggedIn(false);
      } catch (error) {
        throw error;
      }
    }
  };

  return (
    <>
      <Navbar expand="lg" className="navbar-dark">
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <img
                src="/images/header-logo.svg"
                width="200"
                height="54"
                className="d-inline-block align-top"
                alt="GrowX"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            {/* <Nav className="align-items-lg-center">
              <Nav.Link as={Link} to={"/tutorial"}>
                Tutorial
              </Nav.Link>
              <Nav.Link as={Link} to={"/about"}>
                About
              </Nav.Link>
              <Nav.Link onClick={handleLearnMoreShow}>Learn More</Nav.Link>
              {isLoggedIn == false ? (
                <Nav.Link onClick={handleShow}>Get Registered</Nav.Link>
              ) : (
                ""
              )}
              <Nav.Link
                onClick={() => {
                  history("/auth");
                }}
              >
                <img
                  src="/images/avatar.png"
                  className="img-fluid pointer"
                  width={30}
                  alt="LOGGED"
                />
              </Nav.Link>
            </Nav> */}
            <button
              style={styles.buttonLearnMore}
              className="header-btn"
              onClick={handleLearnMoreShow}
            >
              LEARN MORE
            </button>
            {isLoggedIn == false ? (
              <button
                style={styles.button}
                onClick={handleShow}
                className="ms-4 header-btn"
              >
                GET REGISTERED
              </button>
            ) : (
              ""
            )}

            <Dropdown autoClose="inside">
              <Dropdown.Toggle
                className="toggle"
                id="dropdown-autoclose-inside"
              >
                <Nav.Link className="ms-lg-3 ms-0 mt-lg-0 mt-2 ">
                  <img
                    src="/images/avatar.png"
                    className="img-fluid pointer"
                    width={30}
                    alt="LOGGED"
                  />
                </Nav.Link>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {isLoggedIn === true ? (
                  <Dropdown.Item
                    onClick={() => {
                      history("/login");
                    }}
                  >
                    Profile
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    onClick={() => {
                      history("/login");
                    }}
                  >
                    Sign In
                  </Dropdown.Item>
                )}
                {isLoggedIn === true ? (
                  <Dropdown.Item onClick={signOuNow}>Sign Out</Dropdown.Item>
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            </Dropdown>

            {/* <Nav>
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to={"/start-growing"}>
              Start Growing
            </Nav.Link>
            <Nav.Link as={Link} to={"/about"}>
              About
            </Nav.Link>
            <Nav.Link as={Link} to={"/tutorial"}>
              Tutorial
            </Nav.Link>
            <Nav.Link as={Link} to={"/pricing"}>
              Pricing
            </Nav.Link>
            <Nav.Link as={Link} to={"/registration"}>
              Registration
            </Nav.Link>
          </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal show={showRegisterModal} onHide={handleClose} size="xl" centered>
        <Modal.Body
          className="register-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          {goBackRoute ? (
            <div
              className="go-back"
              onClick={() => {
                handleClose();
                handleLearnMoreShow();
                setGoBackRoute("");
              }}
            >
              <img src="./images/go-back.svg" className="" alt="close" />
            </div>
          ) : (
            ""
          )}
          <div className="close-modal" onClick={handleClose}>
            <img src="./images/close.svg" className="" alt="close" />
          </div>
          <RegisterForm />
        </Modal.Body>
      </Modal>
      <Modal
        show={showLearnMoreModal}
        onHide={handleLearnMoreClose}
        size="xl"
        centered
      >
        <Modal.Body
          className="learn-more-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          <div className="close-modal" onClick={handleLearnMoreClose}>
            <img src="./images/close.svg" className="" alt="close" />
          </div>
          <LearnMoreContent
            openRegisterModal={() => {
              openRegisterModal();
              setGoBackRoute("true");
            }}
            openTermConditionModal={() => {
              openTermConditionModal();
              setGoBackRoute("true");
            }}
            openHgpTermConditionModal={() => {
              openHgpTermConditionModal();
              setGoBackRoute("true");
            }}
          />
        </Modal.Body>
      </Modal>
      <Modal show={showTermsModal} onHide={handleTermsClose} size="xl" centered>
        <Modal.Body
          className="terms-condition-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          {goBackRoute ? (
            <div
              className="go-back"
              onClick={() => {
                handleTermsClose();
                handleLearnMoreShow();
                setGoBackRoute("");
              }}
            >
              <img src="./images/go-back.svg" className="" alt="close" />
            </div>
          ) : (
            ""
          )}
          <div className="close-modal" onClick={handleTermsClose}>
            <img src="./images/close.svg" className="" alt="close" />
          </div>
          <TermsConditionsContent
            openRegisterModal={() => {
              openRegisterModal();
              setGoBackRoute("true");
            }}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showHgpTermsModal}
        onHide={handleHgpTermsClose}
        size="xl"
        centered
      >
        <Modal.Body
          className="terms-condition-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          {goBackRoute ? (
            <div
              className="go-back"
              onClick={() => {
                handleHgpTermsClose();
                handleLearnMoreShow();
              }}
            >
              <img src="./images/go-back.svg" className="" alt="close" />
            </div>
          ) : (
            ""
          )}
          <div className="close-modal" onClick={handleHgpTermsClose}>
            <img src="./images/close.svg" className="" alt="close" />
          </div>
          <HgpTermsConditionsContent
            openRegisterModal={() => {
              openRegisterModal();
              setGoBackRoute("true");
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default NewHeader;
