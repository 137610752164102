import React from "react";
import Timer from "../../shared/Timer";
import "./FullReleaseContent.css";

function FullReleaseContent(props) {
  const { openLearnMoreModal } = props;
  return (
    <div className="full-release">
      <h1>GrowX A.i Tech Release Dates</h1>
      <div className="releases border-bottom mb-4">
        <div className="row">
          <div className="col-lg-auto col-12">
            <h2>HomeGrown Pros, INC</h2>
            <div className="pb-1">
              <Timer
                targetDate={new Date("2024-04-08 00:00:00")}
                timerSize="sm"
              />
            </div>
          </div>
          <div className="col-lg col-12 align-self-center">
            <div className="text-right">
              <img
                src="./logos/hgp.png"
                width={140}
                className="img-fluid mb-3"
                alt="HGP"
              />
            </div>
            <div className="text-right mb-3">
              <button
                className="theme-btn px-2 pe-2 "
                onClick={openLearnMoreModal}
              >
                LEARN MORE
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="releases border-bottom mb-4">
        <div className="row">
          <div className="col-lg-auto col-12">
            <h2>CloneX A.I</h2>
            <div className="pb-1">
              <Timer
                targetDate={new Date("2024-06-08 00:00:00")}
                timerSize="sm"
              />
            </div>
          </div>
          <div className="col-lg col-12 align-self-center">
            <div className="text-right">
              <img
                src="./logos/clonez.png"
                width={140}
                className="img-fluid mb-3"
                alt="HGP"
              />
            </div>
            <div className="text-right mb-3">
              <button
                className="theme-btn px-2 pe-2 "
                onClick={openLearnMoreModal}
              >
                LEARN MORE
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="releases border-bottom mb-4">
        <div className="row">
          <div className="col-lg-auto col-12">
            <h2>HomeGrown Apparel & Merch</h2>
            <div className="pb-1">
              <Timer
                targetDate={new Date("2024-06-08 00:00:00")}
                timerSize="sm"
              />
            </div>
          </div>
          <div className="col-lg col-12 align-self-center">
            <div className="text-right">
              <img
                src="./logos/apparel.png"
                width={140}
                className="img-fluid mb-3 apparel"
                alt="HGP"
              />
            </div>
            <div className="text-right mb-3">
              <button
                className="theme-btn px-2 pe-2 "
                onClick={openLearnMoreModal}
              >
                LEARN MORE
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="releases border-bottom mb-4">
        <div className="row">
          <div className="col-lg-auto col-12">
            <h2>HgxTV, LLC</h2>
            <div className="pb-1">
              <Timer
                targetDate={new Date("2024-05-08 00:00:00")}
                timerSize="sm"
              />
            </div>
          </div>
          <div className="col-lg col-12 align-self-center">
            <div className="text-right">
              <img
                src="./logos/hgxtv.png"
                width={140}
                className="img-fluid mb-3"
                alt="HGP"
              />
            </div>
            <div className="text-right mb-3">
              <button
                className="theme-btn px-2 pe-2 "
                onClick={openLearnMoreModal}
              >
                LEARN MORE
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="releases mb-4">
        <div className="row">
          <div className="col-lg-auto col-12">
            <h2>KingMamba, Enterprise</h2>
            <div className="pb-1">
              <Timer
                targetDate={new Date("2024-06-08 00:00:00")}
                timerSize="sm"
              />
            </div>
          </div>
          <div className="col-lg col-12 align-self-center">
            <div className="text-right">
              <img
                src="./logos/mamba.png"
                width={140}
                className="img-fluid mb-3 mamba"
                alt="HGP"
              />
            </div>
            <div className="text-right mb-3">
              <button
                className="theme-btn px-2 pe-2 "
                onClick={openLearnMoreModal}
              >
                LEARN MORE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FullReleaseContent;
